import React from 'react'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import { Link, graphql } from 'gatsby'

import Layout from 'src/components/layout'
import Row from 'src/components/row'

const Page = ({
  data: {
    site,
    imgResidentialHeader,
    imgKingsway1,
    imgKingsway2,
    imgSunnylea1,
    imgSunnylea2,
  },
}) => (
  <Layout>
    <div className="content">
      <Helmet>
        <title>
          Home Construction and Renovation - {site.siteMetadata.title}
        </title>
      </Helmet>
      <h2>Experts in Design, Build, and Renovation</h2>
      <Img
        className="center"
        style={{
          maxWidth: imgResidentialHeader.childImageSharp.original.maxWidth,
        }}
        fluid={imgResidentialHeader.childImageSharp.fluid}
      />

      <hr />

      <h2>Residential Services</h2>
      <Row columns={2}>
        <div>
          <p>
            From fixing a leaky tap to building the home of your dreams, ARC
            prides itself in offering customers a range of professional services
            in residential design, build, repairs and renovations.
          </p>
        </div>
        <div>
          <p>
            Our projects range in scope and size from small renovations,
            additions and even complete custom homes. We also offer in house
            design consultations and project estimation services.
          </p>
          <Link to="/gallery">View more examples</Link>
        </div>
      </Row>

      <hr />
      <Row columns="2">
        <div>
          <h2>The Kingsway (1)</h2>
          <Img
            style={{
              maxWidth: 300,
            }}
            fluid={imgKingsway1.childImageSharp.fluid}
          />
          <p>
            This residence had a 2nd storey addition and porch expansion.
            Natural stone exterior with concrete pillars at entrance way.{' '}
            <Link to="/the-kingsway-residence-1">More Photos...</Link>
          </p>
        </div>
        <div>
          <h2>Sunnylea (1)</h2>
          <Img
            style={{
              maxWidth: 300,
            }}
            fluid={imgSunnylea1.childImageSharp.fluid}
          />
          <p>
            This home, originally a 1920's bungalow, was extensively renovated.
            A second story was added, and the home was expanded, modernized, and
            completed with tasteful high-end finishes in the modern classic
            style. It was clad in a combination of cultured stone and red brick.{' '}
            <Link to="/sunnylea-1">More Photos...</Link>
          </p>
        </div>
      </Row>
      <Row columns="2">
        <div>
          <h2>Sunnylea (2)</h2>
          <Img
            style={{
              maxWidth: 300,
            }}
            fluid={imgSunnylea2.childImageSharp.fluid}
          />
          <p>
            This Sunnylea home was originally a compact, three-bedroom two-story
            house. It was expanded into a more spacious, fully modern two story
            home with an addition to the rear and a completely new interior.{' '}
            <Link to="/sunnylea-2">More Photos...</Link>
          </p>
        </div>
        <div>
          <h2>The Kingsway (2)</h2>
          <Img
            style={{
              maxWidth: 300,
            }}
            fluid={imgKingsway2.childImageSharp.fluid}
          />
          <p>
            Arc thoroughly renovated this Kingsway-area residence. A small,
            1960's-era four bedroom home with dormer windows was completely
            transformed into a spacious, executive home with upstairs laundry
            room and fully finished basement with hardwoord floors throughout.{' '}
            <Link to="/the-kingsway-residence-2">More Photos...</Link>
          </p>
        </div>
      </Row>
    </div>
  </Layout>
)

export default Page

export const query = graphql`
  query ResidentialQuery {
    site {
      siteMetadata {
        title
      }
    }
    imgResidentialHeader: file(
      relativePath: { regex: "/residential-header.jpg/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
        original {
          width
        }
      }
    }
    imgKingsway1: file(
      relativePath: { regex: "/residential/Kingsway-feature-1.png/" }
    ) {
      ...Thumb
    }
    imgKingsway2: file(
      relativePath: { regex: "/residential/Kingsway-feature-2.png/" }
    ) {
      ...Thumb
    }
    imgSunnylea1: file(
      relativePath: { regex: "/residential/Sunnylea-feature-1.png/" }
    ) {
      ...Thumb
    }
    imgSunnylea2: file(
      relativePath: { regex: "/residential/Sunnylea-feature-2.png/" }
    ) {
      ...Thumb
    }
  }
`
